// 引入自定义函数
import { sleep, startLoading, endLoading } from '@/utils/function';
import DownLoad from '../../../components/DownLoad.vue';
let echarts = require('echarts/lib/echarts');
// 引入柱状图组件
require('echarts/lib/chart/bar');

import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
import API from '@/api/teacher/developer/virtualExp';
import { nprogressStart, nprogressClose } from '@/plugins/nprogress.js';
export default {
  name: 'virtualExp',
  components: {
    DownLoad,
  },

  data() {
    return {
      // 上传图片接口地址
      uploadImagePic: process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/adminExp/uploadImagePic",
      // 预览图片url
      dialogImageUrl: '',
      dialogVisible: false,
      virtualDate: [],
      activeName: 'first',
      expList: [],
      exp_id: '',
      // 虚拟试验详情
      AddFormData: {
        article_id: '',
        des: '',
        pic: [],
        logo: '',
        parameter: [],
      },
      parameter: [],
      // fileList: [],
      // articleList: [],
      parameter_table: [],
      schoolList: '',
      personalNumber: '',
      personalTimes: '',
      PieChart: {},
      table: [],
      page: 1,
      // showCharts: false,
      showListNumber: 0,
      staticDialog: false,
      census_browse: 0,
      census_total: 0,
    };
  },
  created() 
  {
    echarts.use([TitleComponent]);
    echarts.use([TooltipComponent]);
    echarts.use([GridComponent]);

    this.exp_id = this.$router.currentRoute.query.exp_id ? this.$router.currentRoute.query.exp_id : '';
    this.virtualDate[0] = this.$router.currentRoute.query.start ? this.$router.currentRoute.query.start : '';
    this.virtualDate[1] = this.$router.currentRoute.query.end ? this.$router.currentRoute.query.end : '';
    this.activeName = this.$router.currentRoute.query.activeName ? this.$router.currentRoute.query.activeName : 'first';
    this.page = this.$router.currentRoute.query.page ? this.$router.currentRoute.query.page : 1;
    this.getDeveloperExpList();
  },
  mounted() {},
  methods: 
  {
    jumpFeedData() 
    {
      let exp_id = this.exp_id ? this.exp_id : this.expList[0].id;
      window.open(
        'https://app.mools.net/backend/public/index.php/developer/exportDeveloperStatistics?exp_id=' +
          exp_id +
          '&start_time=' +
          this.virtualDate[0] +
          '&end_time=' +
          this.virtualDate[1]
      );
    },
    getNextData(e) 
    {
      let self = this;
      self.page = e;
      let data = {
        exp_id: self.exp_id ? self.exp_id : self.expList[0].id,
        start_time: self.virtualDate[0],
        end_time: self.virtualDate[1],
        page: self.page,
      };
      nprogressStart();
      self.$Loading.show();
      API.getDeveloperStatistics(data).then(res => {
        nprogressClose();
        self.$Loading.hide();
        self.schoolList = res.data.showList;
        while (self.schoolList.length < 10) 
        {
          self.schoolList.push({
            id: '',
            name: '',
            num: '',
            student: '',
          });
        }
        self.personalNumber = res.data.personalNumber;
        self.personalTimes = res.data.personalTimes;
        self.PieChart = res.data.PieChart;
        self.inSchoolNumber = res.data.PieChart.inSchoolNumber;
        self.outSchoolNumber = res.data.PieChart.outSchoolNumber;
        self.census_browse = res.data.census_browse;
        self.census_total = res.data.census_total;
        self.$router.push({
            path: '/DY/zonglan',
            query: {
              exp_id: self.exp_id,
              activeName: self.activeName,
              start: self.virtualDate[0],
              end: self.virtualDate[1],
              page: self.page,
            },
          })
          .then(() => {
            nprogressClose();
            self.updateCssStyle(self.exp_id);
          });
      });
    },

    drawEchartsBar1() 
    {
      let self = this;
      let arr1 = [];
      let arr2 = [];
      self.table?.forEach((item, index) => {
        arr1.push('关卡' + (index + 1));
        arr2.push(parseFloat(item.avgTime - 0));
      });
      let maxY = Math.max(...arr2);
      // let arr1=["关卡1","关卡2","关卡3","关卡4","关卡5","关卡6"]
      // let arr2 = [0.2,0.3,0.5,0.1,0.6,1]
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('bar1'));
      let chart = Highcharts.chart('bar1', {
        chart: {
          type: 'column',
        },
        title: {
          text: '通关平均时间',
        },
        xAxis: {
          categories: arr1,
        },
        yAxis: {
          min: 0,
          max: maxY,
          title: {
            text: '平均时间/min',
          },
        },
        colors: ['#3E95B5'],
        series: [
          {
            name: '平均时间',
            data: arr2,
            pointWidth: 15,
          },
        ],
      });
    },

    createPie() 
    {
      let self = this;
      var options = {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 70,
            beta: 0,
          },
        },
        title: {
          text: '',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
          },
        },
        colors: ['#3E95B5', '#F5BD5C'],
        series: [
          {
            type: 'pie',
            name: '人数比',
            data: [
              ['本校', self.inSchoolNumber],
              ['外校', self.outSchoolNumber],
            ],
          },
        ],
      };
      // 图表初始化函数
      var chart = Highcharts.chart('container', options);
    },

    confirmgetstatisData() 
    {
      let self = this;
      self.staticDialog = false;
      let data = {
        exp_id: self.exp_id ? self.exp_id : self.expList[0].id,
        start_time: self.virtualDate[0],
        end_time: self.virtualDate[1],
        page: self.page,
      };
      nprogressStart();
      self.$Loading.show();
      API.getDeveloperStatistics(data).then(res => {
        nprogressClose();
        self.$Loading.hide();
        self.schoolList = res.data.showList;
        while (self.schoolList.length < 10) 
        {
          self.schoolList.push({
            id: '',
            name: '',
            num: '',
            student: '',
          });
        }
        self.personalNumber = res.data.personalNumber;
        self.personalTimes = res.data.personalTimes;
        self.census_browse = res.data.census_browse;
        self.census_total = res.data.census_total;
        self.table = res.data.table ? res.data.table : [];
        self.PieChart = res.data.PieChart;
        self.inSchoolNumber = res.data.PieChart.inSchoolNumber;
        self.outSchoolNumber = res.data.PieChart.outSchoolNumber;
        self.showListNumber = res.data.showListNumber;
        this.createPie();
        this.drawEchartsBar1();
        self.$router
          .push({
            path: '/DY/zonglan',
            query: {
              exp_id: self.exp_id,
              activeName: self.activeName,
              start: self.virtualDate[0],
              end: self.virtualDate[1],
              page: self.page,
            },
          })
          .then(() => {
            // self.page = self.$router.currentRoute.query.page
            self.updateCssStyle(self.exp_id);
          });
      });
    },

    getstatisData() 
    {
      let self = this;
      let startTime = new Date(this.virtualDate[0]).getTime();
      let endTime = new Date(this.virtualDate[1]).getTime();
      if (!startTime || !endTime) 
      {
        return this.$Tips({
          // 消息提示内容
          message: '请选择统计时间',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          displayTime: 1500,
        });
      }
      if (endTime - startTime > 31536000000) 
      {
        this.staticDialog = true;
        return;
      }
      let data = {
        exp_id: self.exp_id ? self.exp_id : self.expList[0].id,
        start_time: self.virtualDate[0],
        end_time: self.virtualDate[1],
        page: self.page,
      };
      nprogressStart();
      self.$Loading.show();
      API.getDeveloperStatistics(data).then(res => {
        nprogressClose();
        self.$Loading.hide();
        self.schoolList = res.data.showList;
        while (self.schoolList.length < 10) 
        {
          self.schoolList.push({
            id: '',
            name: '',
            num: '',
            student: '',
          });
        }
        self.personalNumber = res.data.personalNumber;
        self.personalTimes = res.data.personalTimes;
        self.census_browse = res.data.census_browse;
        self.census_total = res.data.census_total;
        self.table = res.data.table ? res.data.table : [];
        self.PieChart = res.data.PieChart;
        self.inSchoolNumber = res.data.PieChart.inSchoolNumber;
        self.outSchoolNumber = res.data.PieChart.outSchoolNumber;
        self.showListNumber = res.data.showListNumber;
        this.createPie();
        this.drawEchartsBar1();
        self.$router
          .push({
            path: '/DY/zonglan',
            query: {
              exp_id: self.exp_id,
              activeName: self.activeName,
              start: self.virtualDate[0],
              end: self.virtualDate[1],
              page: self.page,
            },
          })
          .then(() => {
            // self.page = self.$router.currentRoute.query.page
            self.updateCssStyle(self.exp_id);
          });
      });
    },

    getDeveloperExpData() 
    {
      let self = this;
      let data = {
        exp_id: self.exp_id ? self.exp_id : self.expList[0]?.id,
      };
      nprogressStart();
      self.$Loading.show();
      // self.fileList = [];
      API.getDeveloperExpData(data).then(res => {
        nprogressClose();
        self.$Loading.hide();
        if (res.code > 0) 
        {
          let pic = '';
          try 
          {
            pic = JSON.parse(res.info.pic);
          } 
          catch (error) 
          {
            console.log(error);
          }
          if (pic) 
          {
            pic?.forEach(item => {
              // self.fileList.push({
              //   name: '',
              //   url: item,
              // });
              // console.log(item);
            });
          }
          for (const key in self.AddFormData) 
          {
            self.AddFormData[key] = res.info[key];
          }
          try 
          {
            self.AddFormData.parameter = JSON.parse(res.info.parameter) ? JSON.parse(res.info.parameter) : [];
            self.parameter = JSON.parse(JSON.stringify(self.AddFormData.parameter));
            let table = JSON.parse(res.info.parameter_table);
            self.parameter_table = JSON.parse(table);
          } 
          catch (error) 
          {
            console.log(error);
          }
          self.parameter?.forEach((item, index) => {
            for (const key in item) {
              if (item[key]?.substring(0, 1) == '#') {
                self.parameter[index][key] = '';
              }
            }
          });
          self.$router
            .push({
              path: '/DY/zonglan',
              query: {
                exp_id: self.exp_id,
                activeName: self.activeName,
                start: self.virtualDate[0],
                end: self.virtualDate[1],
                page: self.page,
              },
            })
            .then(() => {
              self.updateCssStyle(data.exp_id);
            });
        } 
        else 
        {
          self.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            displayTime: 1500,
          });
        }
      });
    },

    getDeveloperExpList() 
    {
      let self = this;
      self.$Loading.show();
      let data = {};
      API.getDeveloperExpList(data).then(res => {
        if (res.code > 0) 
        {
          self.expList = res.list;
          if (self.expList.length > 0 && self.exp_id == '') 
          {
            sleep(50).then(() => {
              self.updateCssStyle(self.expList[0].id);
            });
          } 
          else 
          {
            sleep(50).then(() => {
              self.updateCssStyle(self.exp_id);
            });
          }
          if (this.activeName == 'first') 
          {
            if (!self.virtualDate[0] || !self.virtualDate[1]) 
            {
              self.$Loading.hide();
              return;
            }
            this.getstatisData();
          } 
          else 
          {
            this.getDeveloperExpData();
          }
          self.$Loading.hide();
        } 
        else 
        {
          self.$Loading.hide();
          self.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            displayTime: 1500,
          });
        }
      });
    },

    tabHandleClick({ name }) 
    {
      let self = this;
      self.activeName = name;
      if (name == 'first') 
      {
        self.$router
          .push({
            path: '/DY/zonglan',
            query: {
              exp_id: self.exp_id,
              activeName: self.activeName,
              start: self.virtualDate[0],
              end: self.virtualDate[1],
              page: self.page,
            },
          })
          .then(() => {
            let exp_id = self.exp_id ? self.exp_id : self.expList[0].id;
            self.updateCssStyle(exp_id);
            if (self.virtualDate[0] && self.virtualDate[1]) {
              self.getstatisData();
            }
          });
      } 
      else 
      {
        self.$router
          .push({
            path: '/DY/zonglan',
            query: {
              exp_id: self.exp_id,
              activeName: self.activeName,
              start: self.virtualDate[0],
              end: self.virtualDate[1],
              page: self.page,
            },
          })
          .then(() => {
            self.getDeveloperExpData();
          });
      }
    },

    getexpInfo(id) 
    {
      this.exp_id = id;
      this.updateCssStyle(id);
      if (this.activeName == 'first') 
      {
        if (!this.virtualDate[0] || !this.virtualDate[1]) 
        {
          this.$router
            .push({
              path: '/DY/zonglan',
              query: {
                exp_id: this.exp_id,
                activeName: this.activeName,
                start: this.virtualDate[0],
                end: this.virtualDate[1],
                page: this.page,
              },
            })
            .then(() => {
              // this.page = this.$router.currentRoute.query.page
              this.updateCssStyle(this.exp_id);
            });
          return;
        }
        this.page = 1;
        this.getstatisData();
      } 
      else 
      {
        this.getDeveloperExpData();
      }
    },

    updateCssStyle(id) 
    {
      // css效果
      var d = document.querySelectorAll('.left-module tr');
      for (var p = d.length; p--; ) 
      {
        if (d[p].id != id) 
        {
          d[p].style.backgroundColor = '#FFFFFF'; /*其他班级*/
          d[p].classList.remove('selectTr');
        } 
        else 
        {
          d[p].style.backgroundColor = '#E5ECF1'; /*点击的班级*/
          d[p].classList.add('selectTr');
        }
      }
    },
  },
};
