<template>
  <div class="virtualExp">
    <div v-if="expList.length > 0">
      <!-- 左侧模块 -->
      <div class="left-module">
        <div class="addnewProject">
          <div>
            <div class="projectListBox">
              <table class="sortableTable">
                <tr :id="item.id" v-for="(item, index) in expList" :key="index">
                  <td class="td-project" @click="getexpInfo(item.id)">
                    {{ item.name }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧模块 -->
      <div class="div border0">
        <!-- <el-tabs v-model="activeName" @tab-click="tabHandleClick">
          <el-tab-pane label="统 计" name="first"> -->
            <div class="virtual">
              <div class="selectVir">
                <el-date-picker v-model="virtualDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                  range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                <!-- 不点统计无法导出 -->
                <el-button class="themecancelButton chartsButton" @click="getstatisData">统计</el-button>
                <el-button class="themecancelButton chartsButton" @click="jumpFeedData">导出</el-button>
              </div>
              <div>
                <transition name="fade">
                  <div class="developTable" v-show="schoolList.length > 0">
                    <div class="tableTitle">
                      <div class="column">学校</div>
                      <div>人次</div>
                      <div>人数</div>
                    </div>
                    <div class="tableContent" v-for="(item, index) in schoolList" :key="index">
                      <div class="column">{{ item.name }}</div>
                      <div>{{ item.num }}</div>
                      <div>{{ item.student }}</div>
                    </div>
                    <div class="tableTitle" style="border-bottom:1px #CCC solid ">
                      <div class="column">合计</div>
                      <div>{{ personalTimes }}</div>
                      <div>{{ personalNumber }}</div>
                    </div>
                    <div class="tableTitle">
                      <div class="column">总计(包括未实名人员)</div>
                      <div>{{ census_total }}</div>
                      <div>{{ census_browse }}</div>
                    </div>
                    <div class="pagenation">
                      <el-pagination :current-page="Number(page)" @current-change="getNextData" background
                        layout="prev, pager, next" :total="showListNumber">
                      </el-pagination>
                    </div>
                  </div>
                </transition>
                <div class="charts">
                  <div class="chartstext" v-show="schoolList.length > 0">本校/外校人数比</div>
                  <div id="container"></div>
                  <!-- 一个柱形图 -->
                  <div id="bar1" class="bar"></div>
                </div>
              </div>
            </div>
            <!-- <div class="save">
              <el-button class="themeButton" @click="jumpFeedBack">意见反馈</el-button>
            </div> -->
          <!-- </el-tab-pane>
        </el-tabs> -->
      </div>
    </div>
    <div v-else>
      <el-card>
        <div class="noCourse">
          <div class="text">暂无虚拟实验</div>
        </div>
      </el-card>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog title="提示" :visible.sync="staticDialog" width="30%">
      <span>
        <h3>统计区间过长,需要较长的加载时间,您确认等待吗</h3>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="staticDialog = false">取 消</el-button>
        <el-button class="themeButton" @click="confirmgetstatisData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="scss" >
  .virtualExp .div
  {
    height: 84vh !important;
  }
  .virtualExp .left-module .projectListBox
  {
      max-height: 75vh !important;
      overflow-y: scroll;
  }
  .virtualExp .el-dialog {
    border-radius: 0.625vw;
  }
  .virtualExp .el-upload-list--picture-card .el-upload-list__item {
    width: 30%;
    height: 15vw;
    overflow: hidden;
  }
  .virtualExp .el-upload-list--picture-card .el-upload-list__item img {
    width: 100%;
    // height: 100%;
  }

  .el-dialog__body {
    padding: 30px 20px;
    padding-top: 5px !important;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    padding-bottom: 30px;
    text-align: center;
  }

  .virtualExp .is-active {
    border-left: 0 !important;
  }

  .weight .el-table .el-table__cell {
    padding: 0 !important;
  }

  .virtualExp .el-tabs__item.is-active {
    color: #0D5570 !important;
    border-radius: 0.2083vw;
  }

  .virtualExp .el-textarea {
    width: 94% !important;
  }

  .virtualExp .highcharts-credits {
    display: none !important;
  }

  .virtualExp .el-tabs__content {
    overflow: visible;
    height: 75vh !important;
    overflow: scroll !important;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .virtualExp .el-tabs__content::-webkit-scrollbar {
    display: none;
  }

  .virtualExp .el-tabs__active-bar {
    height: 0.2083vw;
    border-radius: 0.2083vw;
    background-color: #0D5570;
  }

  .virtualExp .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #3E95B5;
    color: #FFF;
  }

  .virtualExp .el-tabs__nav-wrap::after {
    content: "";
    height: 0.2083vw;
    border-radius: 0.2083vw;
    z-index: 0 !important;
  }

  .virtualExp .el-tabs__item {
    padding: 0 1.0417vw;
    // margin-right: 1.25rem;
    height: 2.0833vw;
    // width: 4.375vw;
    text-align: center;
    font: normal normal normal 0.8333vw Source Han Sans CN;
  }

  .virtualExp .el-tabs {
    font: normal normal normal 0.8333vw Source Han Sans CN !important;
  }

  .virtualExp .el-range-editor.el-input__inner {
    width: 21.5vw;
  }

  .virtualExp .el-input.is-disabled .el-input__inner {}
</style>
<style lang="scss" scoped>
  @import "@/assets/css/teacher/developer/virtualExp.scss";
</style>

<script>
  import Calendar from "@/assets/js/DY/zonglan.js";
  export default {
    ...Calendar
  };
</script>
